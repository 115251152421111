<template>

    <div v-if="!isExport" class="calc-results calc-results-pdf">

        <div class="bill">
            <table>
                <tbody>
                    <tr class="tbl-title">
                        <td colspan="4"><strong>BILL OF QUANTITIES</strong></td>
                    </tr>
                    <tr>
                        <td class="result-label">{{results ? results.loadTypeAquabox : 'Aquabox ST'}}</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxST : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Aquabox Sidewall Grids</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxSidewallGrids : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Caps</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.caps : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">{{results ? results.loadTypeCube : 'Aquabox Cube ST'}}</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxCubeST : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Cube Sidewall Grids</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.cubeSidewallGrids : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">D400 Caps</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.d400Caps : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Double Joints*</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.doubleJoints : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Single Joints</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.singleJoints : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">D4 Connector</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.d4Connector : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">O Ring</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.oRing : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Currugated Pipe Ø400</td>
                        <td class="result-value result-value-left" colspan="3">{{results ? results.currugatedPipe400 : '/'}} pcs</td>
                    </tr>
                    <tr>
                        <td class="result-label">Impermeable Liner</td>
                        <td class="result-acronym">{{results ? results.impermeableLiner : '/'}} m<sup>2</sup></td>
                        <td class="result-value width">{{results ? results.impermeableLinerSft : '/'}} sft</td>
                    </tr>
                    <tr>
                        <td class="result-label">Geotextile</td>
                        <td class="result-acronym">{{results ? results.geotextile : '/'}} m<sup>2</sup></td>
                        <td class="result-value width">{{results ? results.geotextileSft : '/'}} sft</td>
                    </tr>
                </tbody>
            </table>
        </div>
            
           
        <div class="structure">
            <table>
                <tbody>
                    <tr class="tbl-title">
                        <td colspan="4"><strong>STRUCTURE DIMENSIONS</strong></td>
                    </tr>
                    <tr>
                        <td class="result-label">Tank Height</td>
                        <td class="result-acronym">{{results ? results.tankHeight : '/'}} m</td>
                        <td class="result-value width">{{results ? results.tankHeightFt : '/'}} ft</td>
                    </tr>
                    <tr>
                        <td class="result-label">Tank Surface</td>
                        <td class="result-acronym">{{results ? results.tankSurface : '/'}} m<sup>2</sup></td>
                        <td class="result-value width">{{results ? results.tankSurfaceSft : '/'}} sft</td>
                    </tr>
                    <tr>
                        <td class="result-label">Gross Volume</td>
                        <td class="result-acronym">{{results ? results.grossVolume : '/'}} m<sup>3</sup></td>
                        <td class="result-value width">{{results ? results.grossVolumeCft : '/'}} cft</td>
                    </tr>
                    <tr>
                        <td class="result-label">Net Storage Volume</td>
                        <td class="result-acronym">{{results ? results.netStorageVolume : '/'}} m<sup>3</sup></td>
                        <td class="result-value width">{{results ? results.netStorageVolumeCft : '/'}} cft</td>
                    </tr>
                </tbody>
            </table>
        </div>
                        
    </div>
    <div v-else class="calc-results calc-results-pdf export">

        <div class="bill"> 
            <div class="table">
                <div class="tbody">
                    <div class="tr tbl-title">
                        <div class="td"><strong>BILL OF QUANTITIES</strong></div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">{{results ? results.loadTypeAquabox : 'Aquabox ST'}}</div>
                        <div class="td result-value result-value-left">{{results ? results.aquaboxST : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Aquabox Sidewall Grids</div>
                        <div class="td result-value result-value-left">{{results ? results.aquaboxSidewallGrids : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Caps</div>
                        <div class="td result-value result-value-left">{{results ? results.caps : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">{{results ? results.loadTypeCube : 'Aquabox Cube ST'}}</div>
                        <div class="td result-value result-value-left">{{results ? results.aquaboxCubeST : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Cube Sidewall Grids</div>
                        <div class="td result-value result-value-left">{{results ? results.cubeSidewallGrids : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">D400 Caps</div>
                        <div class="td result-value result-value-left">{{results ? results.d400Caps : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Double Joints*</div>
                        <div class="td result-value result-value-left">{{results ? results.doubleJoints : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Single Joints</div>
                        <div class="td result-value result-value-left">{{results ? results.singleJoints : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">D4 Connector</div>
                        <div class="td result-value result-value-left">{{results ? results.d4Connector : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">O Ring</div>
                        <div class="td result-value result-value-left">{{results ? results.oRing : '/'}} pcs</div>
                    </div>
                    <div class="tr">
                        <div class="td result-label">Currugated Pipe Ø400</div>
                        <div class="td result-value result-value-left">{{results ? results.currugatedPipe400 : '/'}} pcs</div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Impermeable Liner</div>
                        <div class="td result-acronym test-class">{{results ? results.impermeableLiner : '/'}} m<sup>2</sup></div>
                        <div class="td result-value width">{{results ? results.impermeableLinerSft : '/'}} sft</div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Geotextile</div>
                        <div class="td result-acronym test-class">{{results ? results.geotextile : '/'}} m<sup>2</sup></div>
                        <div class="td result-value width">{{results ? results.geotextileSft : '/'}} sft</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="structure"> 
            <div class="table">
                <div class="tbody">
                    <div class="tr tbl-title">
                        <div class="td"><strong>STRUCTURE DIMENSIONS</strong></div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Tank Height</div>
                        <div class="td result-acronym">{{results ? results.tankHeight : '/'}} m </div>
                        <div class="td result-value width">{{results ? results.tankHeightFt : '/'}} ft </div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Tank Surface</div>
                        <div class="td result-acronym">{{results ? results.tankSurface : '/'}} m<sup>2</sup></div>
                        <div class="td result-value width">{{results ? results.tankSurfaceSft : '/'}} sft</div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Gross Volume</div>
                        <div class="td result-acronym">{{results ? results.grossVolume : '/'}} m<sup>3</sup></div>
                        <div class="td result-value width">{{results ? results.grossVolumeCft : '/'}} cft</div>
                    </div>
                    <div class="tr column-3">
                        <div class="td result-label">Net Storage Volume</div>
                        <div class="td result-acronym">{{results ? results.netStorageVolume : '/'}} m<sup>3</sup></div>
                        <div class="td result-value width">{{results ? results.netStorageVolumeCft : '/'}} cft</div>
                    </div>
                </div>
            </div>
        </div>
                        
    </div>
</template>

<script>
export default {
  
    name: 'pdf-table',
  
    props: {
        results: null,
        isExport : false
    },
    
    data(){
        return {
            
            }  
        },

};
</script>

<style scss>
.calc-results-pdf {
    position: absolute !important;
    top: 8% !important;
    right: 2.6% !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 8px;
    /* width: 210px; */
    /* margin: 0; */
    .bill {
        margin:0 !important;
        table {
            width:225px !important;
            .tbl-title {
                td {
                    font-size: 12px !important;
                } 
            }
            td {
                padding: 2px 0 2px 0 !important;
            }
        }
    }
    .structure {
        margin:0 !important;
        table {
            width:225px !important;
        }
        .tbl-title {
            td {
                font-size: 12px !important;
            }    
        }
    }
    .result-acronym {
        vertical-align: bottom;
    }
    .result-value {
        vertical-align: bottom;
    }

}

.export {
    font-family: Arial, sans-serif;
    position: absolute;
    top: 7% !important;
    right: 2.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 15px;
    
    .structure {
        padding-top: 15px;
    }
    .bill {
    }
    .table {
        border-collapse: collapse;
        width: 362px;
        color: gray;
        display: table;
        .tbl-title {
            .td {
                padding-bottom: 3px;
                font-size: 20px;
                color: black;
            } 
        }
        .tr {
            display: flex;
            /* flex-direction: column; */
            justify-content: space-between;
            border-bottom: solid 1px rgba(193, 193, 193, 0.42);
            color: black;
        }
        .td {
            padding: 3px 0 3px 0;
        }
        .column-3 {
            .result-label {
                
                width: 40%;
            }
            .result-acronym {
                left: unset;
                width: 30%;
                text-align: right;
                justify-content: end;
                vertical-align: bottom;
            }
            .result-value {
                width: 30%;
                text-align: right;
                justify-content: end;
                vertical-align: bottom;
            }
        }
        .result-label {
            color: gray;
        }
        
        /* .test-class {
            position: absolute !important;
            right: 10px !important;
            left: unset !important;
            text-align: right;
        } */
    }
}
</style>